import './index.less';
import { Button, message, InputNumber } from 'antd';
import myContext from '@/context';
import { useContext, useEffect, useState } from 'react';
import TentacleToken from '../../hardhat/artifacts/contracts/Tentacle.sol/Token.json';
import { ethers } from 'ethers';
import Decimal from 'decimal.js';

let TentacleTokenContract;

export default function Index() {
  const { providers, account, tentacleTokenAddr } = useContext(myContext);
  const [tentacleTokenInfo, setTentacleTokenInfo] = useState(['--', '--', '--', '--', '--', '--', '--', '--', '--']);
  const [burnLoading, setBurnLoading] = useState(false);
  const [burnInput, setBurnInput] = useState();
  //const [burnValuePrice, setBurnValuePrice] = useState('--');
  //const [tokenPrice, setTokenPrice] = useState();

  useEffect(() => {
    getBese()
    //if (providers) getBese();
  }, [providers, account, tentacleTokenAddr]);

  const getBese = async () => {
    try {
      TentacleTokenContract = new ethers.Contract(tentacleTokenAddr, TentacleToken.abi, providers.getSigner());
      let baswinfo = await TentacleTokenContract.getBaseInfo();
      //console.log(baswinfo[0]);

      let name = baswinfo[0];
      let symbol_ = baswinfo[1];
      let decimals_ = baswinfo[2];
      let totalSupply_ = baswinfo[3];
      let inittotalSupply_ = baswinfo[4];
      let TxFee = baswinfo[5];
      let _UnderpinningUsdtPrice = baswinfo[6];
      let _swapPrice = baswinfo[7];
      let _burnUsdt = baswinfo[8];

      // let name = await TentacleTokenContract.name();
      // let symbol_ = await TentacleTokenContract.symbol();
      // let decimals_ = await TentacleTokenContract.decimals();
      // let totalSupply_ = await TentacleTokenContract.totalSupply();
      // let inittotalSupply_ = await TentacleTokenContract.initTotalSupply();
      // let TxFee_ = await TentacleTokenContract.TxFee();
      // let price_ = await TentacleTokenContract.getUnderpinningPrice();
      // let balance = await TentacleTokenContract.balanceOf(account.toString());
      // //setTokenPrice(price_.toString());

      _UnderpinningUsdtPrice = new Decimal(_UnderpinningUsdtPrice.toString()).div(new Decimal(10).pow(decimals_.toString())).toFixed(10);
      _swapPrice = new Decimal(_swapPrice.toString()).div(new Decimal(10).pow(decimals_.toString())).toFixed(10);
      totalSupply_ = new Decimal(totalSupply_.toString()).div(new Decimal(10).pow(decimals_.toString())).toFixed(6);
      inittotalSupply_ = new Decimal(inittotalSupply_.toString()).div(new Decimal(10).pow(decimals_.toString())).toFixed(6);
      _burnUsdt = new Decimal(_burnUsdt.toString()).div(new Decimal(10).pow(decimals_.toString())).toFixed(6);
      TxFee = TxFee.toString() + '%';



      setTentacleTokenInfo([name.toString(), symbol_.toString(), decimals_.toString(), totalSupply_.toString(), inittotalSupply_.toString(), TxFee, _UnderpinningUsdtPrice.toString(), _swapPrice.toString(), _burnUsdt.toString()]);
    } catch (err) {
      console.log(err);
    }
  };

  const burn = async () => {
    try {
      if (providers) {
        if (!burnInput) return;
        setBurnLoading(true);

        let tx = await TentacleTokenContract.activeBurn(burnInput);
        await tx.wait();
        setBurnLoading(false);
      } else {
        message.warning('请先连接钱包');
      }
    } catch (err) {
      setBurnLoading(false);
    }
  };

  return (
    <div className="home-page position-relative">
      <div className="content-wrapper bg-white round-mx p-4">
        <div>
          <h1 className="text-max">BNB智能链</h1>
          <svg t="1669895549569" viewBox="0 0 1101 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10434" width="5rem" height="5rem">
            <path
              d="M52.224 575.488q-24.576 0-41.472-16.384t-16.896-40.96l0-76.8q0-24.576 16.896-41.472t41.472-16.896l741.376 0q-18.432 11.264-29.696 26.624t-11.264 30.72l0 40.96 1.024 31.744q-6.144 1.024-17.408 1.024l-69.632 0q-82.944 0-91.136 61.44l-523.264 0zM179.2 317.44q-23.552 0-40.96-16.896t-17.408-41.472l0-70.656q0-23.552 17.408-41.472t40.96-17.92l780.288 0q23.552 0 40.448 17.92t16.896 41.472l0 70.656q0 24.576-16.896 41.472t-40.448 16.896l-780.288 0zM711.68 770.048q18.432 0 28.16-2.048t14.336 1.024 5.632 14.336 1.024 37.888l0 7.168q0 4.096 1.024 7.168l-709.632 0q-24.576 0-41.472-17.408t-16.896-40.96l0-76.8q0-23.552 16.896-40.96t41.472-17.408l521.216 0 0 23.552q0 45.056 25.088 74.24t58.88 29.184l20.48 0q4.096 0 6.144 0.512t7.168 0.512l20.48 0zM1079.296 604.16q7.168 6.144 10.752 14.336t4.096 17.408-2.048 16.896-8.704 11.776q-16.384 14.336-44.544 31.744t-59.392 36.352-60.928 37.376-48.128 32.768q-23.552 20.48-34.816 21.504t-11.264-28.672l0-51.2q0-20.48-8.704-28.672t-25.088-8.192l-122.88 0q-14.336 0-25.088-7.68t-10.752-26.112l0-51.2q0-31.744 9.728-38.4t35.328-6.656l19.456 0q7.168 0 17.408 0.512t25.6 0.512l44.032 0q23.552 0 32.256-15.872t8.704-35.328l0-48.128q0-20.48 8.192-26.112t25.6 8.704q18.432 13.312 48.128 31.744t62.464 37.888 62.464 38.4 48.128 34.304z"
              p-id="10435"
              fill="#ffca4e"
            ></path>
          </svg>
        </div>
        <ul className="py-4">
          <li className="flex-item align-items-start mb-3">
            <div className="flex-content color-gray">名称</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[0]}</div>
              <div className="unit text-right color-gray" />
            </div>
          </li>
          <li className="flex-item align-items-start mb-3">
            <div className="flex-content color-gray">符号</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[1]}</div>
              <div className="unit text-right color-gray" />
            </div>
          </li>
          <li className="flex-item align-items-start mb-3">
            <div className="flex-content color-gray">精度</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[2]}</div>
              <div className="unit text-right color-gray" />
            </div>
          </li>
          <li className="flex-item align-items-start mb-3">
            <div className="flex-content color-gray">当前总量</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[3]}</div>
              <div className="unit text-right color-gray" />
            </div>
          </li>
          <li className="flex-item align-items-start mb-3">
            <div className="flex-content color-gray">初始总量</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[4]}</div>
              <div className="unit text-right color-gray" />
            </div>
          </li>
          <li className="flex-item align-items-start mb-3">
            <div className="flex-content color-gray">交易费率</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[5]}</div>
              <div className="unit text-right color-gray" />
            </div>
          </li>
          <li className="flex-item align-items-start mb-3">
            <div className="flex-content color-gray">当前托底价格 ≈</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[6]} $</div>
              <div className="unit text-right color-gray"></div>
            </div>
          </li>
          <li className="flex-item align-items-start mb-3">
            <div className="flex-content color-gray">交易所价格 ≈</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[7]} $</div>
              <div className="unit text-right color-gray" ></div>
            </div>
          </li>
          {/* <li className="flex-item align-items-start mb-3">
            <div className="flex-content color-gray">当前持有Token总价值 ≈</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[8]}</div>
              <div className="unit text-right color-gray">$</div>
            </div>
          </li> */}
          <li className="flex-item align-items-start">
            <div className="flex-content color-gray">已销毁TENTACLE总价值 ≈</div>
            <div className="flex-label flex-center-center ml-5">
              <div className="text-bold">{tentacleTokenInfo[8]} $</div>
              <div className="unit text-right color-gray"> </div>
            </div>
          </li>
        </ul>
        <div className="flex-item border-t pt-4">
          <div className="flex-content no-overflow">
            <Button className="d-width-button flex-label ml-4" type="primary" shape="round" size="large" href={"https://pancakeswap.finance/swap?outputCurrency=" + tentacleTokenAddr} target="_blank">
              购买TENTACLE
          </Button>
          </div>
        </div>
        <div className="flex-item border-t pt-4">
          <div className="flex-content no-overflow">
            <InputNumber
              className="w-100"
              placeholder="请输入销毁数量"
              controls={false}
              min={0}
              onChange={async () => {
                if (!providers) return message.warning('请先连接钱包');
                setBurnInput(window.event.target.value);
                // let price_ = (window.event.target.value * tokenPrice) / 10 ** 18;
                // setBurnValuePrice(price_.toString());
              }}
            />
          </div>
          <Button className="d-width-button flex-label ml-4" type="primary" shape="round" size="large" danger onClick={burn} loading={burnLoading}>
            销毁
          </Button>
        </div>
      </div>
      <div className="graph1">
        <div className="bg-light p-6 round-circle">
          <img src="/logo.png" alt="logo" />
        </div>
      </div>
      <div className="graph2 bg-light round-circle">
        <img src="/logo.png" alt="" />
      </div>
    </div>
  );
}
