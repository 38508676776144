import { useContext } from 'react';
import myContext from '@/context';
import { Button, message, Input, Select } from 'antd';
import { useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';


export default function Index() {
  const { providers } = useContext(myContext);
  // console.log(providers);
  const [ContractAddress, setContractAddress] = useState("");
  const [Mainnet, setMainnet] = useState('0');
  return (
    <div>
      <Select
        defaultValue='BSC mainnet'
        style={{
          // width: 120,
        }}
        onChange={(value) => {
          console.log(value);
          setMainnet(value);
        }}
        options={[
          {
            label: 'BSC mainnet',
            value: '0',
          },
          {
            label: 'BSC test',
            value: '1',
          },

        ]}
      />
      <div>合约地址:</div>
      <Input onChange={(event) => {
        console.log(event.target.value);
        setContractAddress(event.target.value);
      }}></Input>
      <Button onClick={async () => {
        if (!ContractAddress) {
          message.warning("请输入地址");
          return
        }
        let addr = ethers.utils.getAddress(ContractAddress);

        console.log(Mainnet, ContractAddress);
        await axios.get("/proxy/api/verifyCode", { params: { mainnet: Mainnet, contractaddress: addr } }).then()
          .catch(function (error) {
            console.log(error);
          })
      }}>一键开源</Button>
    </div>

  );
}
