import Home from '@/views/Home';
import TokenToolsDeploy from '@/views/TokenTools/Deploy';
import TokenToolsOpenSource from '@/views/TokenTools/OpenSource';

const routes = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/tokenTools/deploy',
    element: <TokenToolsDeploy />
  },
  {
    path: '/tokenTools/openSource',
    element: <TokenToolsOpenSource />
  }
];

export default routes;
