import './index.less';
import { Button, Input, InputNumber, message } from 'antd';
import { useContext, useState } from 'react';
import tokenByteCode from '../../../hardhat/token/bytecodes/token.json';
import tokenAbi from '../../../hardhat/token/ABI/token.json';
import myContext from '@/context';
import { ethers } from 'ethers';
import copy from 'copy-to-clipboard';
import axios from 'axios';

export default function Index() {
  let { providers, tentacleTokenAddr, BlockChainUrl } = useContext(myContext);
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [tokenDecimals, setTokenDecimals] = useState(18);
  const [tokenTotalSupply, setTokenTotalSupply] = useState('');
  const [deployLoading, setDeployLoading] = useState(false);
  const [address, setAddress] = useState('--');

  const DeployBaseToken = async () => {
    try {
      if (providers) {
        if (tokenName.length && tokenSymbol.length && tokenTotalSupply.length) {
          setDeployLoading(true);
          let TokenContract = new ethers.ContractFactory(tokenAbi, tokenByteCode.object, providers.getSigner());
          let options = {
            //value: ethers.utils.parseEther('0.1'),
          };
          let contractObj = await TokenContract.deploy(tokenName, tokenSymbol, tokenTotalSupply, tokenDecimals, tentacleTokenAddr, options);


          // console.log('contractAddress=', contractObj.address);
          // console.log('deploy txHash=', contractObj.deployTransaction.hash);
          //let contractObjs = await contractObj.deployTransaction.wait();
          //console.log('deploy', contractObj.deployTransaction.data, contractObj.deployTransaction.data.substring(2, contractObj.deployTransaction.data.length));
          //await contractObj.deployed();
          let data = contractObj.deployTransaction.data.substring(2, contractObj.deployTransaction.data.length);
          let parameter = data.substring(tokenByteCode.object.length, data.length);
          await axios.get("/proxy/api/SaveCode", { params: { contract: contractObj.address, data: parameter } }).then()
            .catch(function (error) {
              console.log(error);
            })
          //console.log(parameter);
          setDeployLoading(false);
          setAddress(contractObj.address);
        } else {
          message.warning('参数错误');
          setDeployLoading(false);
        }
      } else {
        message.warning('请先连接钱包');
      }
    } catch (err) {
      if (err.code === 'ACTION_REJECTED') {
        message.warning('交易取消');
      } else {
        message.warning('未知错误');
      }
      //console.log(err);
      setDeployLoading(false);
    }
  };

  const onCopy = () => {
    copy(address);
    message.success('复制成功');
  };

  const onCopyURL = () => {
    if (address == '--') {
      return;
    }
    //console.log(BlockChainUrl + address);

    copy(BlockChainUrl + address);
    message.success('复制成功');
  };

  return (
    <div className="deploy-page">
      <div className="content-wrapper bg-white round-mx">
        <div className="logo-title position-relative overflow-hidden">
          <div className="p-4">
            <h1 className="text-max">一键发币,自动开源</h1>
            <svg t="1669892863659" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5719" width="5rem" height="5rem">
              <path d="M124.2 512c0 214.2 173.6 387.8 387.8 387.8S899.8 726.1 899.8 512 726.2 124.2 512 124.2 124.2 297.8 124.2 512z" fill="#FFD96B" p-id="5720"></path>
              <path d="M786.3 237.9L237.9 786.4c151.5 151.3 396.9 151.3 548.3-0.1 151.4-151.5 151.5-396.9 0.1-548.4z" fill="#FDC223" p-id="5721"></path>
              <path d="M230.1 512c0 155.7 126.2 281.9 281.9 282 155.7 0 281.9-126.2 282-281.9v-0.1c0-155.7-126.2-281.9-281.9-282s-282 126.2-282 282c0-0.1 0-0.1 0 0z" fill="#F9AB10" p-id="5722"></path>
              <path d="M705.3 307.2c-110.4-104.3-284.4-102.7-392.6 5.5-108.1 108.2-109.8 282.2-5.5 392.6 0-0.1 398.1-398.1 398.1-398.1z" fill="#F9B721" p-id="5723"></path>
              <path d="M487.8 591.7h-69.4v-41.4h69.4v-22.4h-69.4v-41.4h48.8L409 368.4h57.6l37.4 79.7c4.8 10 8.1 18.8 10.1 26.4 2.3-8.2 5.7-17.1 10.1-26.4l38.2-79.7H620l-58.9 118.1h49.6v41.4h-70.4v22.4h70.4v41.4h-70.4v87.6h-52.5v-87.6z" fill="#D3830D" p-id="5724"></path>
              <path d="M564.5 479.7l-112 112h35.2v87.6h52.6v-87.6h70.4v-41.4h-70.4v-22.4h70.4v-41.4h-49.6l3.4-6.8z" fill="#BF790A" p-id="5725"></path>
            </svg>
          </div>
          <img className="logo" src="/logo.png" alt="" />
        </div>
        <ul className="px-4">
          <li className="flex-item align-items-center mb-3">
            <div className="flex-content color-gray">代币名称</div>
            <div className="flex-label">
              <Input
                disabled={deployLoading}
                onChange={() => {
                  setTokenName(window.event.target.value);
                }}
              />
            </div>
          </li>
          <li className="flex-item align-items-center mb-3">
            <div className="flex-content color-gray">币种符号</div>
            <div className="flex-label">
              <Input
                disabled={deployLoading}
                onChange={() => {
                  setTokenSymbol(window.event.target.value);
                }}
              />
            </div>
          </li>
          <li className="flex-item align-items-center mb-3">
            <div className="flex-content color-gray">总量</div>
            <div className="flex-label">
              <InputNumber
                disabled={deployLoading}
                controls={false}
                onChange={() => {
                  setTokenTotalSupply(window.event.target.value);
                }}
              />
            </div>
          </li>
          <li className="flex-item align-items-center">
            <div className="flex-content color-gray">精度</div>
            <div className="flex-label">
              <InputNumber
                disabled={deployLoading}
                max={18}
                min={0}
                defaultValue={18}
                onChange={(value) => {
                  if (value) {
                    setTokenDecimals(value);
                  } else {
                    setTokenDecimals(0);
                  }
                }}
              />
            </div>
          </li>
        </ul>
        <div className="p-4">
          <Button className="w-100" size="large" shape="round" type="primary" loading={deployLoading} onClick={DeployBaseToken}>
            一键发币
          </Button>
        </div>
        <div className="border-t p-4">
          <div className="flex-item align-items-center">
            <div className="flex-label color-gray">合约地址</div>
            <h3 className="ml-4 flex-content text-right">{address}</h3>
          </div>
          <div className="py-3">
            <Button className="w-100" size="large" shape="round" type="primary" onClick={onCopy}>
              点击复制
            </Button>

          </div>
          <div className="py-3">
            <Button className="w-100" size="large" shape="round" type="primary" href={address != '--' ? 'http://' + BlockChainUrl + address : 'javascript:;'} onClick={onCopyURL}>
              打开区块浏览器
            </Button>
          </div>
          {/* <div className="flex-item align-items-center">
            <div className="flex-label color-gray">公钥</div>
            <h3 className="ml-4 flex-content text-right">{encryptionPublicKey}</h3>
          </div> */}
          {/* <div className="py-3">
            <Button className="w-100" size="large" shape="round" type="primary" danger onClick={setEncryptionPublicKey}>
              获取公钥
            </Button>
          </div> */}
        </div>
      </div>
    </div >
  );
}
