import { useState, useReducer, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from '@/router';
import './App.less';
import { Button, Dropdown, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { commonSelector } from '@/store/slice/common';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import messages from './lang';
import { Provider } from '@/context';
import Svg from './components/Svg';
import MenuList from './components/MenuList';
import { ethers } from 'ethers';
// import { use } from 'chai';
// import TentacleToken from './hardhat/artifacts/contracts/TentacleToken.sol/Token.json';

let Accounts;
let ChainInfo = 97;
let ChainInfos;
let Providers;

const tentacleTokenAddr = '0xD79386c05049D4d83810812E119D33C29164d0A1';

const menuInitialState = { defaultSelectedKeys: [], defaultOpenKeys: [], isTouch: false, collapsed: true };
const menuReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'setDefaultKeys': {
      const [defaultSelectedKeys, defaultOpenKeys] = payload;
      return { ...state, defaultSelectedKeys: [defaultSelectedKeys], defaultOpenKeys: defaultOpenKeys ? [defaultOpenKeys] : [] };
    }
    case 'toTouchScreen': {
      return { ...state, isTouch: true, collapsed: true };
    }
    case 'toPcScreen': {
      return { ...state, isTouch: false, collapsed: false };
    }
    case 'toggleCollapsed': {
      return { ...state, collapsed: !state.collapsed };
    }
    default: {
      return state;
    }
  }
};

export default function App() {
  const elements = useRoutes(routes);
  const location = useLocation();
  const { locale } = useSelector(commonSelector);
  const [BlockChainUrl, setBlockChainUrl] = useState('testnet.bscscan.com/address/');
  // 切换链下拉选项
  const chainList = [
    {
      key: '56',
      label: (
        <div className="d-flex align-items-center">
          <img alt="" className="chain-icon" src={require('@img/97.png')} />
          <div onClick={
            () => {
              changeChain(56);
              setBlockChainUrl('bscscan.com/address/');
            }}>Binance Smart Chain</div>
        </div>
      )
    },
    {
      key: '97',
      label: (
        <div className="d-flex align-items-center">
          <img alt="" className="chain-icon" src={require('@img/97.png')} />
          <div onClick={() => {
            ConnectWallet(97);
            setBlockChainUrl('testnet.bscscan.com/address/');
          }}
          >Binance Smart Chain Testnet</div>
        </div>
      )
    }
  ];

  const [inited, setInited] = useState(false);
  const [menuState, menuDispatch] = useReducer(menuReducer, menuInitialState);

  const [providers, setProviders] = useState(null);
  const [account, setAccounts] = useState('连接钱包');
  const [Account, setAccount] = useState('连接钱包');
  const [ConnectWalletLoading, setConnectWalletLoading] = useState(false);

  //const [TentacleTokenInfo, setTentacleTokenInfo] = useState([]);
  //const [TentacleTokenContracts, setTentacleTokenContracts] = useState();

  useEffect(() => {
    if (!inited) {
      // 清除pre-loading动画
      const preLoadingEle = document.getElementsByClassName('pre-loading')[0];
      if (preLoadingEle) preLoadingEle.parentNode.removeChild(preLoadingEle);

      // 设置默认选中菜单
      const pathArr = location.pathname.slice(1).split('/').reverse();
      menuDispatch({ type: 'setDefaultKeys', payload: pathArr });

      judgeWindowWidth(true);
      setInited(true);
    }

    // 事件监听注册 每次更新需重新注册 否则形成menuState的闭包
    window.addEventListener('resize', judgeWindowWidth);
    return () => {
      window.removeEventListener('resize', judgeWindowWidth);
    };
  }, [menuState]);

  // 判断设备尺寸
  const judgeWindowWidth = () => {
    const width = window.innerWidth;
    if (width <= 768 && (!inited || !menuState.isTouch)) {
      menuDispatch({ type: 'toTouchScreen' });
    } else if (width > 768 && (!inited || menuState.isTouch)) {
      menuDispatch({ type: 'toPcScreen' });
    }
  };

  function isMetaMaskInstalled() {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  const changeChain = (value) => {
    ChainInfo = value;
    ConnectWallet();
  };

  let ConnectWallet = async () => {
    try {
      if (isMetaMaskInstalled()) {
        if (ChainInfos && ChainInfo == ChainInfos.chainId) {
          return;
        }
        setConnectWalletLoading(true);

        Providers = new ethers.providers.Web3Provider(window.ethereum);

        setProviders(Providers);

        await GetAccount();
        await chainChanged();
        setConnectWalletLoading(false);
        await GetGameInfo();
        Providers.on('block', async () => {
          await GetGameInfo();
        });
        await window.ethereum.on('chainChanged', async function () {
          Providers = new ethers.providers.Web3Provider(window.ethereum);
          setProviders(Providers);
          ChainInfos = await Providers.getNetwork();
          console.log('chainChanged', ChainInfos.chainId);
          setAccount(Accounts.substring(0, 10) + '...' + Accounts.substring(33, 42).toString());
          await chainChanged();
          await GetGameInfo();
        });
        window.ethereum.on('accountsChanged', async function () {
          await GetAccount();
        });
      } else {
        message.warning('没有检测到钱包插件!');
        return;
      }
    } catch (err) {
      setConnectWalletLoading(false);
      console.log(err);
    }
  };

  let chainChanged = async () => {
    try {
      Providers = new ethers.providers.Web3Provider(window.ethereum);
      setProviders(Providers);
      ChainInfos = await Providers.getNetwork();
      console.log('ChainInfos.chainId', ChainInfos.chainId, '0x' + ChainInfo.toString(16));
      if (ChainInfos.chainId !== ChainInfo) {
        setAccount('请选择正确的区块网络');
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x' + ChainInfo.toString(16) }]
        });
      } else {
        setAccount(Accounts.substring(0, 10) + '...' + Accounts.substring(33, 42).toString());
      }
    } catch (err) {
      console.log(err);
    }
  };

  let GetAccount = async () => {
    try {
      await window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((accounts) => {
          Accounts = accounts[0];
          setAccounts(Accounts);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  let GetGameInfo = async () => {
    try {
      //console.log(1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
      {inited && (
        <div className="app w-100 h-100 flex-item bg-white">
          <aside className="flex-label h-100">
            <MenuList {...menuState} toggleMenu={() => menuDispatch({ type: 'toggleCollapsed' })} />
          </aside>
          <article className={`flex-content flex-vertical ${menuState.isTouch ? 'is-touch' : ''}`}>
            <header className={`flex-header d-flex justify-content-between align-items-center px-3 border-b ${menuState.isTouch ? 'is-touch' : ''}`}>
              <div className="flex-label flex-center-center">
                <div onClick={() => menuDispatch({ type: 'toggleCollapsed' })} className="cursor-pointer flex-center-center">
                  <Svg name={menuState.collapsed ? 'unfold' : 'fold'} size="1.8" fill="#1a1d3e" hover="primary" />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Button shape="round" className="flex-center-center" onClick={ConnectWallet} loading={ConnectWalletLoading}>
                  <Svg name="wallet" size="1.2" fill="#ffcd36" />
                  <div className="ml-1">{Account}</div>
                </Button>
                <Dropdown menu={{ items: chainList }} placement="bottom" arrow={{ pointAtCenter: true }}>
                  <Button shape="round" className="flex-center-center">
                    <Svg name="chain" size="1.2" fill="#2fa73f" />
                    {!menuState.isTouch && <div className="ml-1">选择链</div>}
                  </Button>
                </Dropdown>
              </div>
            </header>
            <main className="flex-content py-5">
              <Provider value={{ providers, tentacleTokenAddr, account, BlockChainUrl }}>{elements}</Provider>
            </main>
          </article>
        </div>
      )}
    </IntlProvider>
  );
}
