import './index.less';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { AppstoreOutlined, MacCommandOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const menuList = [
  {
    label: <FormattedMessage id="menu.home" />,
    key: '',
    icon: <AppstoreOutlined />
  },
  {
    label: 'Token工具箱',
    key: 'tokenTools',
    icon: <MacCommandOutlined />,
    children: [
      {
        label: '一键发币',
        key: 'deploy'
      },
      // {
      //   label: '一键开源',
      //   key: 'openSource'
      // }
    ]
  }
];

export default function Index(props) {
  const navigate = useNavigate();
  const { defaultSelectedKeys, defaultOpenKeys, isTouch, collapsed, toggleMenu } = props;

  // 路由切换
  const onClickMenu = (menu) => {
    const path = menu.keyPath.reverse().reduce((pre, cur) => `${pre}/${cur}`, '');

    navigate(path);

    if (isTouch) toggleMenu();
  };

  return (
    <>
      <div className={`menu-list h-100 bg-white flex-vertical ${collapsed ? 'fold' : ''}`}>
        <div className={`logo-box flex-header flex-item align-items-center overflow-hidden ${!isTouch && collapsed ? 'fold' : ''}`}>
          <div className="logo flex-label flex-center-center">
            <img src="/logo.png" alt="logo" />
          </div>
          <div className="flex-label flex-vertical text-center text-bold">
            <div className="text-mini">WEB3 TENTACLE.COM</div>
          </div>
        </div>
        <div className="flex-content">
          <Menu defaultSelectedKeys={defaultSelectedKeys} defaultOpenKeys={defaultOpenKeys} mode="inline" inlineCollapsed={!isTouch && collapsed} onClick={onClickMenu} items={menuList} />
        </div>
      </div>
      {isTouch && !collapsed ? <div className="menu-bg w-100 h-100" onClick={toggleMenu} /> : ''}
    </>
  );
}
